import { sendSentryException } from '../assets/inner/js/vue/utils/sendSentryException';

// Create a container element where errors will spawn
document.addEventListener('DOMContentLoaded', () => {
  const notifierWrapper = document.createElement('div');
  notifierWrapper.id = 'dprecoro-error-notifier-container';
  notifierWrapper.classList.add('dprecoro-error-notifier-container');
  document.body.appendChild(notifierWrapper);
});

function dprecoroGetErrorNotifierContainerEl() {
  return document.getElementById('dprecoro-error-notifier-container');
}

function dprecoroAppendErrorToContainer(element) {
  setTimeout(() => {
    const container = dprecoroGetErrorNotifierContainerEl();
    if (container) {
      container.appendChild(element).scrollIntoView();
    } else {
      dprecoroAppendErrorToContainer(element);
    }
  }, 100);
}

/**
 * Show notification of an error
 * @param {'error'|'network-error'} type
 * @param {string|Array} message
 */
function dprecoroErrorNotifier(type, message) {
  if ((type !== 'error' && type !== 'network-error') || !message) {
    return;
  }

  let text = message;
  let cssClass = type;
  if (type === 'network-error') {
    text = Array.isArray(message) ? message.join('<br>') : message;
    cssClass = 'error';
  } else if (typeof message === 'object') {
    text = JSON.stringify(message);
  }

  if (window.ignoreErrors) {
    for (const ignoreError of window.ignoreErrors) {
      if (text.includes(ignoreError)) {
        return;
      }
    }
  }

  if (
    text.startsWith('🍍') || // pinia
    text.startsWith('[vee-validate]') ||
    text.startsWith('[Vue-Treeselect Warning]') ||
    // pinia devtools error
    text.includes('devtools-plugin:') ||
    // pinia devtools error
    text.includes('__VUE_DEVTOOLS_APP_RECORD_ID__')
  ) {
    return;
  }

  const div = document.createElement('div');
  div.setAttribute('data-timestamp', new Date().valueOf().toString());
  div.setAttribute('data-type', type);

  if (
    text.startsWith('TypeError') ||
    text.startsWith('ReferenceError') ||
    text.startsWith('Uncaught ReferenceError') ||
    text.startsWith('SyntaxError') ||
    text.startsWith('Uncaught Error') ||
    text.startsWith('URIError')
  ) {
    text = `<strong><span>${getEmoji()}</span>${text}</strong>`;
  }
  div.innerHTML = `<h3 class="dprecoro-error-notifier-error ${cssClass}">${text}</h3>`;

  setTimeout(() => div.remove(), 7000);

  dprecoroAppendErrorToContainer(div);
}

function getEmoji() {
  const emojis = ['🌚', '👀', '🤌🏾', '🤡', '💩', '🤬', '🆘', '🛑', '🔥', '🟥', '🫠'];
  return emojis[parseInt(Math.random() * (emojis.length - 1))];
}

// Patch browser console
(function() {
  /**
   * @param {'error'|'warn'} type
   * @param {function} originalMethod
   * @param {function|function[]} callbacks
   */
  function patchConsole(type, originalMethod, callbacks) {
    return function(...args) {
      if (type === 'error') {
        dprecoroErrorNotifier(type, args.join(' '));
      }

      if (callbacks) {
        const argsString = args.join(' ');
        if (typeof callbacks === 'function') {
          callbacks(argsString);
        } else if (Array.isArray(callbacks)) {
          callbacks.forEach(cb => {
            if (typeof cb === 'function') {
              cb(argsString);
            }
          });
        }
      }

      originalMethod.call({}, ...args);
    };
  }

  const unknownTranslationKeys = {};

  /** @param {string} args */
  function sendUnknownTranslationsToSentry(args) {
    // Example warning: [vue-i18n] Value of key 'unknown.translation_key' is not a string or function !
    if (!args.startsWith('[vue-i18n]')) {
      return;
    }

    const match = args.match(/Value of key '([\w\d_.]+)' is not a string or function/);
    if (!match || !Array.isArray(match) || !match[1]) {
      return;
    }

    const translationKey = match[1];
    // key is already sent to Sentry
    if (unknownTranslationKeys[translationKey]) {
      return;
    }

    const errorMessage = `Unknown Translain key: ${translationKey}`;
    if (window.location.hostname === 'd.precoro.com') {
      sendSentryException(new Error(errorMessage));
    }
    console.error(errorMessage);
    unknownTranslationKeys[translationKey] = true;
  }

  window.console.error = patchConsole('error', window.console.error);
  window.console.warn = patchConsole('warn', window.console.warn, [sendUnknownTranslationsToSentry]);
})();

// Log errors
window.addEventListener('error', errorEvent => {
  dprecoroErrorNotifier(errorEvent.type, errorEvent.message);
});

// Log unhandled rejections
window.addEventListener('unhandledrejection', errorEvent => {
  dprecoroErrorNotifier('error', 'Unhandled Promise rejection: ' + errorEvent.reason);
});

function dprecoroSetErrorNotifierBottomMargin(number) {
  if (number === 0) {
    dprecoroGetErrorNotifierContainerEl()?.style.removeProperty(
      '--dprecoro-error-notifier-bottom-margin',
    );
  } else {
    dprecoroGetErrorNotifierContainerEl()?.style.setProperty(
      '--dprecoro-error-notifier-bottom-margin',
      `${number + 8}px`,
    );
  }
}

// If Symfony Profiler toolbar is open, add bottom margin error notifier container
window.addEventListener('load', () => {
  const symfonyToolbarDisplayState = localStorage.getItem('symfony/profiler/toolbar/displayState');
  if (symfonyToolbarDisplayState) {
    dprecoroSetErrorNotifierBottomMargin(symfonyToolbarDisplayState === 'block' ? 36 : 0);
  }

  // setTimeout because it seems like Symfony Profiler removes event listeners on these 2 buttons before setting their own
  setTimeout(() => {
    document.querySelector('[id^=sfToolbarMiniToggler]')?.addEventListener('click', () => {
      dprecoroSetErrorNotifierBottomMargin(
        document.querySelector('[id^=sfToolbarMainContent]')?.offsetHeight ?? 0,
      );
    });
    document.querySelector('[id^=sfToolbarHideButton]')?.addEventListener('click', () => {
      dprecoroSetErrorNotifierBottomMargin(
        document.querySelector('[id^=sfToolbarMainContent]')?.offsetHeight ?? 0,
      );
    });
  }, 1000);
});
